.App {
  text-align: center;
  max-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mail-info{
  margin-top: 5px;
  margin-bottom: 0.5px;
  cursor: pointer;
}
.home-container{
  background-color: rgba(0, 0, 0, 1) !important;
  position: relative;
  resize: contain;
  height: auto !important;
  max-width: 100vw;
}
.home-description{
  text-align: center;
  color: white;
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.software-title{
  color: white;
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.home-description2{
  padding-right: 100px;
  padding-left: 100px;
  margin-top: 50px;
  text-align: center;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.fa-chevron-down{
  color: white;
  scale: 3;
  margin-bottom: -50px;
}
.footer-container{
  margin-top: 70px !important;
}
.footer-title{
  font-weight: 700;
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer-content{
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.footer-city{
  margin-top: -20px !important;
}
.services-description{
  margin-top: 40px;
  font-size: 20px;
  color: white;
  font-weight: 700;
  /* background-color: rgba(0, 0, 0, 0.6); */
  
}
.service-content{
  display: flex;
  justify-content: center;
  margin: 6rem 0 6rem 0 !important;
}
.services-cards{
  width: 320px !important;
  height: 200px !important;
  border-radius: 30px !important;
  object-fit: contain !important;
  background: none !important;
  margin-bottom: 2rem !important;
  /* margin-right: 15px !important; */
}
.card-img{
  border-radius: 20px !important;
}
.card-title{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 -4px 0 -4px;
  height: 40px;
}
.card-title{
  color: white !important;
  margin-top: 60px;
  font-weight: 700;
  font-size: 18px;
}
.footer-root{
  text-decoration: none;
  color: black;
  display: block !important;
}
.software-description{
  color: white;
  margin: 25px 0;
}
.services-banner{
  margin-left: 10px !important;
}
.navbar{
  /* margin-top: -20px; */
}